import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import { Section } from '../components/Section';
import { OnlyTextHeroSection } from '../pageSections/OnlyTextHeroSection';
import { IntegrationOptionsSection } from '../pageSections/integraciones/IntegrationOptionsSection';
import { IntegrationOptionDescriptions } from '../pageSections/integraciones/IntegrationOptionDescriptions';
import { Layout } from '../layout/Layout';

const sectionMapping = {
  Strapi_ComponentPageSectionsOnlyTextHeroSection: OnlyTextHeroSection,
  Strapi_ComponentPageSectionsIntegrationOptionsSection: IntegrationOptionsSection,
  Strapi_ComponentPageSectionsIntegrationOptionDescriptions: IntegrationOptionDescriptions,
};

export default ({ data }) => {
  const { seoInfo, sections } = data.strapi.integrationsPage;
  return (
    <Layout variant="enterprise">
      <SEO
        title={seoInfo.pageTitle}
        description={seoInfo.pageDescription}
        keywords={seoInfo.keywords}
      />
      {sections.map(s => {
        return (
          <Section
            backgroundVariant="integrations"
            mapping={sectionMapping}
            key={`${s.type}${s.id}`}
            variant="enterprise"
            {...s}
          />
        );
      })}
    </Layout>
  );
};

export const query = graphql`
  query {
    strapi {
      integrationsPage {
        seoInfo {
          pageDescription
          pageTitle
          keywords
        }

        sections {
          type: __typename
          ... on Strapi_ComponentPageSectionsOnlyTextHeroSection {
            id
            title
            subtitle
            cta {
              ...CTA
            }
          }

          ... on Strapi_ComponentPageSectionsIntegrationOptionsSection {
            id
            title
            option1 {
              title
              description
            }

            option2 {
              title
              description
            }
          }

          ... on Strapi_ComponentPageSectionsIntegrationOptionDescriptions {
            id
            option1 {
              title
              description
              ctaText
              ctaUrl
              image {
                ...Image
              }
              backgroundText
            }

            option2 {
              title
              description
              ctaText
              ctaUrl
              image {
                ...Image
              }
              backgroundText
            }
          }
        }
      }
    }
  }
`;
