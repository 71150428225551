import React from 'react';
import { Container } from '../../components/Container';
import styled from 'styled-components';
import { Grid as BaseGrid } from '../../components/Grid';
import GatsbyImage from 'gatsby-image';
import { H3 } from '@increasecard/typed-components';
import { CTAButton } from '../../components/CTAButton';
import { MEDIUM } from '../../breakpoints';
import { H1 } from '../../components/Typography';

const Grid = styled(BaseGrid)`
  align-items: center;

  @media (min-width: ${MEDIUM}) {
    grid-auto-flow: column dense;
  }
`;

const BaseWrapper = styled.div`
  padding: 96px 0;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const OptionTextWrapper = styled.div`
  position: relative;
  height: max-content;
`;
const OptionImageWrapper = styled.div`
  margin-top: 32px;

  @media (min-width: ${MEDIUM}) {
    margin-top: 0;
  }
`;

const Option1Wrapper = styled(BaseWrapper)`
  background: ${({ theme }) => theme.colorsNew.gray.white};
  color: ${({ theme }) => theme.colorsNew.gray.black};

  @media (min-width: ${MEDIUM}) {
    ${OptionImageWrapper} {
      grid-column-start: 5;
    }
  }
`;
const Option2Wrapper = styled(BaseWrapper)`
  background: ${({ theme }) => theme.colorsNew.gray.black};
  color: ${({ theme }) => theme.colorsNew.gray.white};

  @media (min-width: ${MEDIUM}) {
    ${OptionImageWrapper} {
      grid-column-start: 1;
    }

    ${OptionTextWrapper} {
      grid-column-start: 6;
    }
  }
`;

const OptionTitle = styled(H1)`
  margin-bottom: 16px;
`;

const OptionDescription = styled(H3).attrs({ weight: 'normal' })``;

const OptionCTA = styled(CTAButton).attrs({ type: 'accent' })`
  margin-top: 32px;
`;

const OptionBackgroundText = styled.div`
  font-weight: bold;
  font-size: 227.399px;
  line-height: 1;
  position: absolute;
  top: -80px;
  z-index: 0;
  opacity: 0.1;

  @media (min-width: ${MEDIUM}) {
    left: -72px;
  }
`;

export function IntegrationOptionDescriptions({ option1, option2 }) {
  return (
    <>
      <Option1Wrapper>
        <Container>
          <Grid>
            <OptionTextWrapper className="grid-span-md-3 grid-span-sm-2">
              <OptionBackgroundText>
                {option1.backgroundText}
              </OptionBackgroundText>
              <OptionTitle>{option1.title}</OptionTitle>
              <OptionDescription
                dangerouslySetInnerHTML={{ __html: option1.description }}
                weight="normal"
              />
              <OptionCTA text={option1.ctaText} href={option1.ctaUrl} />
            </OptionTextWrapper>
            <OptionImageWrapper className="grid-span-md-4 grid-span-sm-2">
              <GatsbyImage {...option1.image.imageSharp.childImageSharp} />
            </OptionImageWrapper>
          </Grid>
        </Container>
      </Option1Wrapper>
      <Option2Wrapper>
        <Container>
          <Grid>
            <OptionTextWrapper className="grid-span-md-3 grid-span-sm-2">
              <OptionBackgroundText style={{ color: 'white' }}>
                {option2.backgroundText}
              </OptionBackgroundText>
              <OptionTitle>{option2.title}</OptionTitle>
              <OptionDescription
                dangerouslySetInnerHTML={{ __html: option2.description }}
                weight="normal"
              />
              <OptionCTA text={option2.ctaText} href={option2.ctaUrl} />
            </OptionTextWrapper>
            <OptionImageWrapper className="grid-span-md-4 grid-span-sm-2">
              <GatsbyImage {...option2.image.imageSharp.childImageSharp} />
            </OptionImageWrapper>
          </Grid>
        </Container>
      </Option2Wrapper>
    </>
  );
}
