import React from 'react';
import { Container as BaseContainer } from '../../components/Container';
import { Grid as BaseGrid } from '../../components/Grid';
import styled from 'styled-components';
import { H3 } from '@increasecard/typed-components';
import { MEDIUM } from '../../breakpoints';
import { SuperHeader, H1 } from '../../components/Typography';

const Wrapper = styled.div`
  color: ${({ theme }) => theme.colorsNew.gray.black};
  background: ${({ theme }) => theme.colorsNew.increase.accent};
  padding: 120px 0;
`;

const Grid = styled(BaseGrid)``;
const Container = styled(BaseContainer)``;
const Title = styled(SuperHeader)`
  margin-bottom: 16px;

  @media (min-width: ${MEDIUM}) {
    margin-bottom: 0;
  }
`;
const Option = styled.div`
  display: flex;
  flex-direction: column;
  padding: 48px 24px;

  :first-child {
    padding-top: 0;
  }
  :last-child {
    padding-bottom: 0;
  }

  :not(:first-child) {
    border-top: 3px solid ${({ theme }) => theme.colorsNew.gray.black};
  }

  @media (min-width: ${MEDIUM}) {
    flex-direction: row;
  }
`;
const OptionNumber = styled.div`
  font-size: 100px;
  line-height: 121px;
  font-weight: bold;
  margin-right: 40px;
  min-width: 140px;
  @media (min-width: ${MEDIUM}) {
    text-align: right;
  }
`;

const OptionContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const OptionTitle = styled(H1)`
  margin-bottom: 8px;
`;
const OptionDescription = styled(H3).attrs({ weight: 'normal' })``;

export function IntegrationOptionsSection({ title, option1, option2 }) {
  return (
    <Wrapper>
      <Container>
        <Grid style={{ alignItems: 'center' }}>
          <div className="grid-span-md-3 grid-span-sm-2">
            <Title>{title}</Title>
          </div>
          <div className="grid-span-md-5 grid-span-sm-2">
            <Option>
              <OptionNumber>1 /</OptionNumber>
              <OptionContent>
                <OptionTitle>{option1.title}</OptionTitle>
                <OptionDescription>{option1.description}</OptionDescription>
              </OptionContent>
            </Option>
            <Option>
              <OptionNumber>2 /</OptionNumber>
              <OptionContent>
                <OptionTitle>{option2.title}</OptionTitle>
                <OptionDescription>{option2.description}</OptionDescription>
              </OptionContent>
            </Option>
          </div>
        </Grid>
      </Container>
    </Wrapper>
  );
}
